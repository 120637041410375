/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';

// Utils
import SCOPES from '../gate/scopes';
import { useAppContext } from '../store/AppContext';
import { isGranted } from '../gate/PermissionsGate';
import handleLighterColor from '../utils/style/handleLighterColor';
import { isSOrder } from '../utils/platform';

// Services
import useKeyAccountService from '../services/client/useKeyAccountService';
import { getMyKeyAccountClient } from '../services/admin/keyAccounts/keyAccountsService';
import SpinLoader from '../../components/atoms/SpinLoader/SpinLoader';

const withKeyAccountContext = (Component) => {
  function KeyAccountContext(props) {
    const [mounted, setMounted] = useState(false);

    const [{ keyAccountContext, user, kaContextReloadCode }, stateDispatch] = useAppContext();
    const [customizationDone, setCustomizationDone] = useState(false);

    const shouldGetMyKeyAccountClientInfos = isGranted(user, [SCOPES.canViewKeyAccountAdminClients]);
    const myKeyAccountClientQuery = useQuery({
      queryKey: ['get-my-key-account'],
      queryFn: getMyKeyAccountClient,
      enabled: shouldGetMyKeyAccountClientInfos,
    });

    const [, , , { getAllKeyAccountsDomains }] = useKeyAccountService();
    const [, , , { getKeyAccountContextData }] = useKeyAccountService();

    useEffect(() => {
      setMounted(true);
    }, []);

    const getCurrentOriginUrl = () => {
      const devTestUrl = isSOrder()
        ? `https://${process.env.NEXT_PUBLIC_EIOS_DOMAIN}`
        : `https://${process.env.NEXT_PUBLIC_DOMAIN}`;
      const env = process?.env?.NODE_ENV;

      if (env === 'development' || env === 'test') return devTestUrl;
      return window?.location?.origin || null;
    };

    const handleKeyAccountColors = (context) => {
      const root = document.documentElement;

      if (root?.style && (context.primaryColor || context.secondaryColor)) {
        if (context.primaryColor) {
          root.style.setProperty('--color-primary', context.primaryColor);
          root.style.setProperty('--color-primary-100', handleLighterColor(context.primaryColor, 147));
        }

        if (context.secondaryColor) {
          root.style.setProperty('--color-black', context.secondaryColor);
        }

        setCustomizationDone(true);
      } else {
        setCustomizationDone(true);
      }
    };

    const handleKeyAccountContextData = (keyAccountCode) => {
      getKeyAccountContextData(keyAccountCode).then((resKeyAccountData) => {
        if (resKeyAccountData?.data) {
          handleKeyAccountColors(resKeyAccountData.data);
          stateDispatch({ type: 'SET_KEY_ACCOUNT_CONTEXT', payload: resKeyAccountData.data });
          Cookies.set('keyAccountContext', JSON.stringify(resKeyAccountData.data));
        } else {
          stateDispatch({ type: 'SET_KEY_ACCOUNT_CONTEXT', payload: {} });
          setCustomizationDone(true);
          Cookies.set('keyAccountContext', JSON.stringify({}));
        }
      });

      stateDispatch({ type: 'SET_KA_CONTEXT_RELOAD_CODE', payload: null });
    };

    useEffect(() => {
      if (myKeyAccountClientQuery.isSuccess) {
        handleKeyAccountColors(myKeyAccountClientQuery.data);
        stateDispatch({ type: 'SET_KEY_ACCOUNT_CONTEXT', payload: myKeyAccountClientQuery.data });
        Cookies.set('keyAccountContext', JSON.stringify(myKeyAccountClientQuery.data));
      }
    }, [myKeyAccountClientQuery.isSuccess]);

    useEffect(() => {
      if (mounted && !shouldGetMyKeyAccountClientInfos) {
        if (keyAccountContext) {
          handleKeyAccountColors(keyAccountContext);
        } else {
          getAllKeyAccountsDomains().then((res) => {
            if (res?.data && getCurrentOriginUrl()) {
              const domains = res?.data;
              const isLocal =
                getCurrentOriginUrl().includes('dev.zol.fr') || getCurrentOriginUrl().includes('localhost');
              const keyAccountCode = domains.find((domain) =>
                isLocal
                  ? domain.url?.includes('zol.fr') || domain.url?.includes('localhost')
                  : domain.url === getCurrentOriginUrl(),
              )?.code;

              if (keyAccountCode) {
                handleKeyAccountContextData(keyAccountCode);
              } else {
                stateDispatch({ type: 'SET_KEY_ACCOUNT_CONTEXT', payload: {} });
                Cookies.set('keyAccountContext', JSON.stringify({}));
                setCustomizationDone(true);
              }
            } else {
              setCustomizationDone(true);
            }
          });
        }
      }
    }, [mounted, shouldGetMyKeyAccountClientInfos]);

    useEffect(() => {
      if (kaContextReloadCode) handleKeyAccountContextData(kaContextReloadCode);
    }, [kaContextReloadCode]);

    if (customizationDone && (keyAccountContext || (user && isGranted(user, [SCOPES.canViewKeyAccountClients])))) {
      return <Component {...props} />;
    }

    return (
      <div className="w-100 flex center" style={{ height: '100vh' }}>
        <SpinLoader width={50} color={keyAccountContext?.primaryColor} />
      </div>
    );
  }

  if (Component.getInitialProps) {
    KeyAccountContext.getInitialProps = Component.getInitialProps;
  }

  return KeyAccountContext;
};

export default withKeyAccountContext;
