const SCOPES = {
  // home
  canViewClientHome: 'canViewClientHome',
  canViewAdminDashboard: 'canViewAdminDashboard',
  canViewKeyAccountAdminDashboard: 'canViewKeyAccountAdminDashboard',
  canViewClientDashboard: 'canViewClientDashboard',

  // layout
  canViewFooter: 'canViewFooter',
  canViewKeyAccountAdminMenu: 'canViewKeyAccountAdminMenu',

  // cart
  canAccessCart: 'canAccessCart',
  canAddToCart: 'canAddToCart',

  // orders
  canViewClientOrders: 'canViewClientOrders',
  canViewAdminOrders: 'canViewAdminOrders',
  canViewMultiCenterOrders: 'canViewMultiCenterOrders',
  canUpdateOrderStatus: 'canUpdateOrderStatus',
  canViewProductBuyingPrice: 'canViewProductBuyingPrice',

  // centers
  canViewCenters: 'canViewCenters',

  // my account
  canViewMyAccount: 'canViewMyAccount',
  canViewMyAccountAsAdmin: 'canViewMyAccountAsAdmin',
  canEditAccountInfo: 'canEditAccountInfo',
  canViewCompanyInfo: 'canViewCompanyInfo',
  canEditCompanyInfo: 'canEditCompanyInfo',
  canViewPaymentMethods: 'canViewPaymentMethods',
  canViewPricesManagment: 'canViewPricesManagment',
  canViewAddresses: 'canViewAddresses',
  canViewClientUsers: 'canViewClientUsers',

  // product catalog
  canViewProductCatalog: 'canViewProductCatalog',

  // nationwide operations
  canViewClientNationwideOperations: 'canViewClientNationwideOperations',

  // -------------------- ADMIN -------------------- //

  canLogInAsAdmin: 'canLogInAsAdmin',

  // suppliers
  canViewSuppliers: 'canViewSuppliers',
  canViewSuppliersMargins: 'canViewSuppliersMargins',
  canViewSuppliersDiscounts: 'canViewSuppliersDiscounts',

  // orders
  canRequestTrackingAndPOD: 'canRequestTrackingAndPOD',
  canAskForRefund: 'canAskForRefund',

  // clients
  canViewClients: 'canViewClients',
  canViewClientAccountComment: 'canViewClientAccountComment',
  canImpersonateUser: 'canImpersonateUser',
  canUpdateClientStatus: 'canUpdateClientStatus',

  // key accounts
  canViewKeyAccountClients: 'canViewKeyAccountClients',
  canCreateKeyAccount: 'canCreateKeyAccount',
  canViewKeyAccountAdminLabel: 'canViewkeyAccountAdminLabel',
  canViewKeyAccountAdminClients: 'canViewKeyAccountAdminClients',
  canViewKeyAccountAdminOrders: 'canViewKeyAccountAdminOrders',
  canViewKeyAccountAdminOrderDetails: 'canViewKeyAccountAdminOrderDetails',
  canViewKeyAccountRegionalAdminLabel: 'canViewkeyAccountAdminLabel',

  // alerts
  canViewAlerts: 'canViewAlerts',
  canViewNotificationAlert: 'canViewNotificationAlert',

  // nationwide operations
  canViewNationwideOperations: 'canViewAdminNationwideOperations',
  canCreateNationwideOperations: 'canCreateNationwideOperations',
  canEditNationwideOperations: 'canEditNationwideOperations',
  canLockOrUnlockParticipation: 'canLockOrUnlockParticipation',
  canEditCenterStatus: 'canEditCenterStatus',
  canValidateParticipation: 'canValidateParticipation',

  // brands
  canViewAdminBrands: 'canViewAdminBrands',

  // flux
  canViewAdminFluxClients: 'canViewAdminFluxClients',
};

export default SCOPES;
